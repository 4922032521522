export default {
  home: {
    seo: {
      title: 'Bienes raíces en Miami: Compra, venta y administración',
      description:
        'Bienes raíces en Miami: compra, venta y alquiler de propiedades con asesoramiento, financiamiento flexible y administración para tu inversión.',
      defaultDescription:
        'En PFS Realty encuentra bienes raices e inmuebles en venta y alquiler en la Florida.',
      singleRent: '{0} en alquiler',
      singleRentAlt: '{0} en alquiler',
      multipleRent: 'Casas y apartamentos en alquiler',
      complementaryTitle:
        'en PFSRealty encuentra bienes raices e inmuebles en venta y alquiler.',
      counties: {
        description:
          'Encuentra propiedades en venta y alquiler en los condados de la Florida.'
      },
      cities: {
        description:
          'Encuentra propiedades en venta y alquiler en las ciudades de {0}, Florida.'
      },
      neighborhoods: {
        description:
          'Encuentra propiedades en venta y alquiler en los vecindarios de {0}, Florida.'
      }
    },
    hero: {
      title: 'Bienes raíces. Venta y alquiler de casas y apartamentos en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Alquilar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Apartamentos y Casas en venta y alquiler en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de apartamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2.000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title:
            '22 años de experiencia en ventas de propiedades en Estados Unidos',
          description:
            'Nuestra prolongada trayectoria como líderes en el mercado de bienes raíces en Miami nos posiciona como tu mejor opción. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 asesores en 12 países de América y Europa',
          description:
            'Con una red internacional de agentes, ofrecemos perspectivas globales y localizadas que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus proyectos de inversión inmobiliaria.'
        },
        three: {
          title: 'Más de 2.000 clientes satisfechos confían en nosotros',
          description:
            'La confianza de nuestros clientes es el reflejo de la dedicación y el conocimiento del mercado de bienes raíces. Somos una de las inmobiliarias en Miami más confiables y respetadas. ¡Únete y experimenta el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'Servicios integrales',
      properties: {
        title: 'Adquiere la propiedad ideal',
        description:
          'Encuentra la propiedad perfecta en Miami y compra inmuebles nuevos y usados de alta calidad. Cada agente inmobiliario cuenta con amplia experiencia y conocimientos para brindarte el asesoramiento especializado que necesitas.'
      },
      financing: {
        title: 'Financiamiento a tu medida',
        description:
          'La compra de un inmueble suele requerir de un financiamiento sólido y adaptado a la capacidad económica del comprador. Por eso, te ofrecemos créditos hipotecarios flexibles y personalizados para colombianos con mínimo 30% de entrada. Adaptamos los términos a tus necesidades.'
      },
      extra_income: {
        title: 'Genera ingresos extras alquilando',
        description:
          'Te brindamos una asistencia integral para transformar tu inmueble en una fuente de ganancias en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de propiedades',
        description:
          'Contamos con un equipo preparado para el cuidado profesional y el mantenimiento preventivo y correctivo de tu casa. Optimizamos costos y beneficios para asegurar una administración eficiente de tu inversión inmobiliaria.'
      },
      legal: {
        title: 'Asesoría legal y contable integral',
        description:
          'Nuestro equipo interdisciplinario de profesionales te proporciona atención y asesoría experta para aclarar tus dudas sobre los aspectos legales y contables que involucran la compra, venta y alquiler de bienes inmuebles. Además, trabajamos para cumplir con todas las normas.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  search: {
    buyTypeOptions: {
      rent: 'Alquilar',
      rented: 'Alquilado | Alquilados'
    },
    availableItems: '{items} en venta y alquiler',
    rentedItems: '{items} alquilados',
    rentedItems_female: '{items} alquiladas',
    soldOrRentedItems: '{items} vendidos o alquilados',
    soldOrRentedItems_female: '{items} vendidas o alquiladas',
    itemsForRent: '{items} en alquiler',
    rentSearchResults: '{items} en alquiler en {search}',
    rentedSearchResults: '{items} alquiladas en {search}',
    buyRentSearchResults: '{items} en venta y alquiler en {search}',
    rentSearchResultsNearYou: '{0} en alquiler cerca de tu ubicación',
    rentedSearchResultsNearYou: '{0} alquiladas cerca de tu ubicación',
    buyRentSearchResultsNearYou:
      '{0} en venta y alquiler cerca de tu ubicación',
    rentResults: 'Propiedades en alquiler',
    rentedResults: 'Propiedades alquiladas'
  },
  property: {
    rented: 'Alquilado',
    rented_female: 'Alquilada'
  },
  project: {
    rentals: 'Alquileres',
    availableCondosForRent: 'Apartamentos disponibles para alquilar',
    pendingCondosForRent: 'Apartamentos pendientes para alquilar',
    closedCondosForRent: 'Apartamentos alquilados'
  },
  footer: {
    rent_label: 'Precio de Alquiler'
  },
  calculator: {
    title: 'Simulador de crédito hipotecario',
    description:
      'Esta herramienta te permite simular el pago mensual de un crédito hipotecario, considerando factores como el capital e intereses, el impuesto predial anual, la cuota de administración y la prima de seguro mensual. <br/>' +
      'Al ingresar los datos sobre el precio del inmueble, la cuota inicial y la tasa de interés, puedes obtener un cálculo preciso de tus cuotas mensuales, facilitando la compra y financiación de tu propiedad a través de una entidad financiera. <br/>',
    hint: 'Esto es esencial para la educación financiera y la planificación de la compra de vivienda propia, permitiéndote gestionar mejor tu historial crediticio y ahorros.',
    monthlyPayments: 'Cuotas mensuales',
    principalInterest: 'Capital e intereses',
    propertyTax: 'Impuesto predial anual',
    associationFee: 'Cuota de administración',
    principalInterestLegend: 'Principal e intereses',
    propertyTaxLegend: 'Impuesto predial',
    associationFeeLegend: 'Cuota de asociación',
    insuranceFee: 'Prima de seguro',
    homePrice: 'Precio',
    propertyTaxYearly: 'Predial anual',
    associationFeeMonthly: 'Cuota de administración mensual',
    insuranceFeeMonthly: 'Prima de seguro mensual',
    downPayment: 'Cuota inicial (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de interés (%)',
    loanType: 'Tipo de crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    title: 'Reporte inmobiliario',
    longTitle: 'Reporte inmobiliario de Miami: Análisis y datos actualizados',
    description:
      'Consulta el reporte inmobiliario de Miami con datos actualizados y segmentados. Compara precios y toma decisiones informadas sobre inversiones',
    help:
      'A través de nuestro reporte inmobiliario de Miami podrás seleccionar la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada, comparar los precios de los inmuebles en diferentes áreas, evaluar el impacto de las tendencias e inversiones, y anticipar cambios en el mercado para planificar mejor tus estrategias de compra, venta o alquiler. <br/>' +
      'Con esta herramienta, accede a datos actualizados y detallados, segmentados por municipio, ciudad, código postal y tipo de propiedad, fundamental para tomar decisiones informadas y planificadas en el sector de bienes raíces. Aprovecha esta valiosa información y consulta el informe completo para optimizar tus inversiones.<br/>',
    recentlyRented: 'Recientemente alquilados',
    buyTypes: {
      rentals: 'Alquileres'
    }
  },
  blog: {
    seo: {
      title: 'Blog PFS Realty Group: Oportunidades Inmobiliarias en Miami'
    },
    title: 'Oportunidades en Miami y el sur de Florida',
    description:
      'Descubre en el Blog PFS Realty Group las mejores oportunidades inmobiliarias en Miami y Florida. Tendencias exclusivas para invertir. ¡Infórmate ahora!',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario. ¡Accede a noticias exclusivas, mantente actualizado sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!'
  }
};
